import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import BackgroundImage from 'gatsby-background-image';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  main: {
    minHeight: '100vh',
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    // '&::before': {
    //   filter: 'brightness(50%) contrast(80%)',
    // },
    // '&::after': {
    //   filter: 'brightness(50%) contrast(80%)',
    // },
  },
  insideMain: {
    minHeight: '100vh',
    filter: 'none',
  },
  grid: {
    paddingTop: theme.spacing(10),
    minHeight: '100vh',
  },
  content: {
    minHeight: '100%',
    minWidth: '100%',
  },
}));

const BackgroundSection = ({
  backgroundPosition,
  backgroundImage,
  children,
}) => {
  const classes = useStyles();

  return (
    <BackgroundImage
      Tag="section"
      className={classes.main}
      style={{ backgroundPosition }}
      fluid={backgroundImage}
      backgroundColor={`#040e18`}
    >
      <div className={classes.insideMain}>
        <Grid className={classes.grid} container>
          <div className={classes.content}>{children}</div>
        </Grid>
      </div>
    </BackgroundImage>
  );
};

BackgroundSection.propTypes = {
  backgroundPosition: PropTypes.string.isRequired,
  backgroundImage: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default BackgroundSection;
