import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Fab } from '@material-ui/core';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const useStyles = makeStyles((theme) => ({
  button: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    zIndex: 1300,
  },
  link: {
    color: 'white',
    textDecoration: 'none',
    minWidth: '100%',
  },
}));

const BookNowButton = () => {
  const classes = useStyles();

  return (
    <Fab
      color="primary"
      aria-label="book"
      variant="extended"
      className={classes.button}
    >
      <AnchorLink to="/book/#booking" className={classes.link}>
        Book now
      </AnchorLink>
    </Fab>
  );
};

export default BookNowButton;
