import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { makeStyles, Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
}));

const PageSection = ({ title, id, bgColor, labelColor, children }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.outerContainer}
      style={{ backgroundColor: bgColor }}
    >
      <Container maxWidth="md">
        {title.split('\\n').map((line) => (
          <Typography
            key={line}
            variant="h3"
            style={{ color: labelColor }}
            className={classes.heading}
            align="center"
            id={id}
            gutterBottom
            display="block"
          >
            {line}
          </Typography>
        ))}
        <Typography
          variant="h3"
          style={{ color: labelColor }}
          className={classes.heading}
          align="center"
          gutterBottom
        >
          {' '}
        </Typography>
        {children}
      </Container>
    </div>
  );
};

PageSection.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  labelColor: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default PageSection;
