import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Typography } from '@material-ui/core';
import MuiLink from '@material-ui/core/Link';
import tsAndCs from '../docs/Terms_and_Conditions.pdf';

const Copyright = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          homepage
        }
      }
    }
  `);

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <MuiLink color="inherit" href={data.site.siteMetadata.homepage}>
        PREP Physio
      </MuiLink>{' '}
      {new Date().getFullYear()}. Read our{' '}
      <MuiLink color="inherit" href={tsAndCs} target="_blank">
        Terms and Conditions
      </MuiLink>
      .
    </Typography>
  );
};

export default Copyright;
