import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import { purples } from '../theme';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
  },
  text: {
    color: 'white',
    textShadow: `0 0 3px ${purples[3]}`,
  },
  noWrap: {
    whiteSpace: 'noWrap',
  },
}));

const TopBanner = ({ heading, texts }) => {
  const classes = useStyles();
  const smallWidth = useMediaQuery('(max-device-width: 400px)');

  return (
    <Grid
      className={classes.container}
      direction="column"
      alignContent="center"
      justify="center"
      container
    >
      <Grid item>
        <Typography
          className={classes.text}
          variant={smallWidth ? 'h3' : 'h2'}
          display="block"
          align="center"
          gutterBottom
        >
          {heading}
        </Typography>
      </Grid>
      {texts && (
        <Grid item>
          <Typography className={classes.text} variant="h4" align="center">
            {texts.map((text) => (
              <Fragment key={text}>
                <span className={classes.noWrap}>{text}</span>{' '}
              </Fragment>
            ))}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

TopBanner.propTypes = {
  heading: PropTypes.string.isRequired,
  texts: PropTypes.array,
};

TopBanner.defaultProps = {
  texts: null,
};

export default TopBanner;
