import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import { graphql, useStaticQuery } from 'gatsby';

const useStyles = makeStyles(theme => ({
  button: {
    cursor: 'pointer'
  }
}));

const InstagramButton = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          instagramUrl
        }
      }
    }
  `);

  return (
    <IconButton
      color="primary"
      aria-label="follow"
      className={classes.button}
      onClick={() => window.open(data.site.siteMetadata.instagramUrl, '_blank')}
    >
      <InstagramIcon color="action" />
    </IconButton>
  );
};

export default InstagramButton;
