import React from 'react';
import { Typography, Box } from '@material-ui/core';
import Copyright from './Copyright';
import { graphql, useStaticQuery } from 'gatsby';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          contact {
            address
            email
            phoneNumber
          }
        }
      }
    }
  `);

  const getAddress = () => data.site.siteMetadata.contact.address;
  const getPhoneNumber = () => data.site.siteMetadata.contact.phoneNumber;
  const getEmail = () => data.site.siteMetadata.contact.email;

  return (
    <Box my={4} style={{ paddingBottom: '20px' }}>
      <Typography variant="body2" color="textSecondary" align="center">
        <span role="img" aria-label="address">
          📍
        </span>
        {getAddress()}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        <span role="img" aria-label="email">
          📧
        </span>{' '}
        <a
          href={`mailto:${getEmail()}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {getEmail()}
        </a>
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        <span role="img" aria-label="phone">
          ☎️
        </span>{' '}
        <a
          href={`tel:${getPhoneNumber()}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {getPhoneNumber()}
        </a>
      </Typography>
      <Copyright />
    </Box>
  );
};

export default Footer;
