import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Logo from '../images/PREP_Rectangle_Logo_PURPLE.png';
import {
  Toolbar,
  AppBar,
  useScrollTrigger,
  Slide,
  Hidden,
  Divider,
  List,
  ListItem,
  Grid,
  Collapse,
} from '@material-ui/core';
import HamburgerButton from './HamburgerButton';
import InstagramButton from './InstagramButton';

const useStyles = makeStyles((theme) => ({
  logo: {
    padding: '0px',
  },
  logoDesktop: {
    padding: '0px',
  },
  appBar: {
    flexGrow: 1,
    backgroundColor: theme.palette.transparent.white1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
    color: 'black',
    textDecoration: 'none',
    '&:last-of-type': {
      marginRight: theme.spacing(2),
    },
  },
  toolbarGrid: {
    marginRight: theme.spacing(2),
  },
  dropdownLink: {
    color: 'black',
    textDecoration: 'none',
    minWidth: '100%',
  },
  navLinks: {
    marginRight: '110px',
  },
}));

const HideOnScroll = ({ mobileOnly, children }) => {
  const trigger = useScrollTrigger();

  const slide = (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );

  if (mobileOnly) {
    return (
      <>
        <Hidden smDown> {children} </Hidden>
        <Hidden mdUp> {slide} </Hidden>
      </>
    );
  }

  return slide;
};

HideOnScroll.propTypes = {
  mobileOnly: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};

const links = [
  {
    name: 'HOME',
    path: '/',
  },
  {
    name: 'SERVICES',
    path: '/#services',
  },
  {
    name: 'ABOUT ME',
    path: '/#aboutMe',
  },
  {
    name: 'BLOG',
    path: '/#blog',
  },
  {
    name: 'CONTACT',
    path: '/#contact',
  },
  {
    name: 'LOCATION',
    path: '/#location',
  },
  {
    name: 'FOLLOW',
    path: '/#feed',
  },
];

const TopBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const classes = useStyles();

  const drawer = (
    <div>
      <Divider />
      <List onClick={() => setMenuOpen(false)}>
        {links.map((link) => (
          <ListItem button key={link.name} divider>
            <AnchorLink to={link.path} className={classes.dropdownLink}>
              <Typography variant="subtitle1">{link.name}</Typography>
            </AnchorLink>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <HideOnScroll mobileOnly>
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar disableGutters>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.toolbarGrid}
          >
            <Hidden mdDown>
              <Grid item>
                <img
                  className={classes.logoDesktop}
                  src={Logo}
                  height="80px"
                  alt="logo"
                />
              </Grid>
              <Grid item>
                <Grid container spacing={3} className={classes.navLinks}>
                  {links.map((link) => (
                    <Grid item key={link.name}>
                      <AnchorLink
                        to={link.path}
                        className={classes.toolbarLink}
                      >
                        <Typography variant="subtitle1">{link.name}</Typography>
                      </AnchorLink>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item>
                <InstagramButton />
              </Grid>
            </Hidden>
            <Hidden lgUp>
              <Grid item>
                <InstagramButton />
              </Grid>
              <Grid item>
                <img
                  className={classes.logo}
                  src={Logo}
                  height="80px"
                  alt="logo"
                />
              </Grid>
              <Grid item>
                <HamburgerButton
                  open={menuOpen}
                  color="black"
                  onClick={() => setMenuOpen(!menuOpen)}
                />
              </Grid>
            </Hidden>
          </Grid>
        </Toolbar>
        <Collapse in={menuOpen} timeout={500}>
          {drawer}
        </Collapse>
      </AppBar>
    </HideOnScroll>
  );
};

export default TopBar;
